// extracted by mini-css-extract-plugin
export var navbarOpen = "Testimonials-module--navbar-open--265iy";
export var isModal = "Testimonials-module--isModal--1WODZ";
export var isLoaded = "Testimonials-module--is-loaded--2zmzy";
export var Plx = "Testimonials-module--Plx--1klqT";
export var customTooltip = "Testimonials-module--customTooltip--GxaY-";
export var calculatorContainer__wrapper = "Testimonials-module--calculatorContainer__wrapper--1TL8m";
export var posRelative = "Testimonials-module--posRelative--3RolL";
export var calculatorSection = "Testimonials-module--calculator-section--1hWSR";
export var refiAdviceSection = "Testimonials-module--refi-advice-section--1O_Wp";
export var faqSection = "Testimonials-module--faq-section--2C9Xl";
export var glossarySection = "Testimonials-module--glossary-section--2_zvg";
export var homeBuyAdvice = "Testimonials-module--home-buy-advice--1eZ0o";
export var ourBeliefs = "Testimonials-module--our-beliefs--3UodH";
export var meetTeam = "Testimonials-module--meet-team--Zkxnb";
export var aboutcake = "Testimonials-module--aboutcake--2Qd_7";
export var ourMission = "Testimonials-module--our-mission--11KzH";
export var ourStory = "Testimonials-module--our-story--1UJCk";
export var mainWrapper = "Testimonials-module--main-wrapper--2pQrA";
export var btn = "Testimonials-module--btn--3s2KT";
export var iconWrapper = "Testimonials-module--icon-wrapper--14Kg7";
export var small = "Testimonials-module--small--2D7kJ";
export var green = "Testimonials-module--green--2GEID";
export var greenFill = "Testimonials-module--green--fill--2HcR0";
export var greenBorder = "Testimonials-module--green--border--18G9g";
export var dark = "Testimonials-module--dark--vYbxQ";
export var darkLg = "Testimonials-module--dark--lg--2M3xP";
export var p1 = "Testimonials-module--p1--14Q-U";
export var p2 = "Testimonials-module--p2--1S2Dh";
export var centerAlign = "Testimonials-module--center-align--2BO2t";
export var leftAlign = "Testimonials-module--left-align--DDlND";
export var section = "Testimonials-module--section--3g_3Z";
export var contentsWrap = "Testimonials-module--contents-wrap--2Z6TT";
export var lDesktop = "Testimonials-module--l-desktop--1lPHd";
export var lMobile = "Testimonials-module--l-mobile--cgEnO";
export var sectionHead = "Testimonials-module--section-head--3iq9t";
export var hidMob = "Testimonials-module--hid-mob--2dXzh";
export var hidDsktp = "Testimonials-module--hid-dsktp--2mp0y";
export var CakeFormWrap = "Testimonials-module--CakeFormWrap--3xY0D";
export var CakeFieldWrap = "Testimonials-module--CakeFieldWrap--aRp5N";
export var inputDollarWrap = "Testimonials-module--input--dollar-wrap--27b8v";
export var customRange = "Testimonials-module--custom-range--18_kP";
export var customRange__track = "Testimonials-module--custom-range__track--2dpYx";
export var customRange__thumb = "Testimonials-module--custom-range__thumb--Brhnv";
export var thumbValue = "Testimonials-module--thumb-value--3YzAr";
export var container = "Testimonials-module--container--2-LkH";
export var containerSm = "Testimonials-module--container--sm--1FYWA";
export var cake404 = "Testimonials-module--cake404--34yzy";
export var cake404__wrapper = "Testimonials-module--cake404__wrapper--3OXT4";
export var cake404__figure = "Testimonials-module--cake404__figure--KJF-Q";
export var cake404__contents = "Testimonials-module--cake404__contents--6W8z5";
export var contents__top = "Testimonials-module--contents__top--34HiB";
export var contents__bottom = "Testimonials-module--contents__bottom--1vy8O";
export var footIcon = "Testimonials-module--footIcon--26uK1";
export var genericSection = "Testimonials-module--generic-section--2oUIa";
export var titleSection = "Testimonials-module--title-section--3_ANI";
export var PersonalizeModal = "Testimonials-module--PersonalizeModal--EPxPX";
export var onetrustConsentSdk = "Testimonials-module--onetrust-consent-sdk--2lPmW";
export var onetrustBannerSdk = "Testimonials-module--onetrust-banner-sdk--1-FX7";
export var onetrustButtonGroup = "Testimonials-module--onetrust-button-group--3foL_";
export var onetrustPcDarkFilter = "Testimonials-module--onetrust-pc-dark-filter--38kiY";
export var onetrustPcSdk = "Testimonials-module--onetrust-pc-sdk--3XYoN";
export var otSdkBtnFloating = "Testimonials-module--ot-sdk-btn-floating--1FgZ5";
export var calculatorContainer = "Testimonials-module--calculatorContainer--2Nw-X";
export var dsktRes = "Testimonials-module--dskt-res--1hrOn";
export var disBtn = "Testimonials-module--dis-btn--2rcSf";
export var otFloatingButton__front = "Testimonials-module--ot-floating-button__front--2dv2V";
export var otFloatingButton__back = "Testimonials-module--ot-floating-button__back--tJ15m";
export var Article__wrapper = "Testimonials-module--Article__wrapper--2qy6P";
export var ArticleBanner = "Testimonials-module--Article-banner--2c5pL";
export var ArticleBanner__top = "Testimonials-module--Article-banner__top--JI1Ry";
export var ArticleBanner__title = "Testimonials-module--Article-banner__title--2-sh9";
export var ArticleBanner__readTime = "Testimonials-module--Article-banner__read-time--3VXNn";
export var ArticleBanner__image = "Testimonials-module--Article-banner__image--1K6cF";
export var ArticleBanner__bottom = "Testimonials-module--Article-banner__bottom--24Cxi";
export var ArticleBanner__subtitle = "Testimonials-module--Article-banner__subtitle--3CELq";
export var ArticleContents = "Testimonials-module--Article-contents--au_pA";
export var Article__sources = "Testimonials-module--Article__sources--1ZcR_";
export var perspectiveWrap = "Testimonials-module--perspectiveWrap--2DPBE";
export var Testimonials = "Testimonials-module--Testimonials--1CtAP";